/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Divider, Grid, Typography } from '@mui/material';
import { Link, NavLink, useParams } from 'react-router-dom';
import { useDrawerContext } from '../contexts/drawer-contexts';
import {
  ItemBox,
  OutletBox,
  DisplayButton,
  ReportChip
} from './Styled-component';
import { getDisplayScreensApi } from '../../api/displayscreens/displayScreenApi';
import { useDispatch, useSelector } from 'react-redux';
import { displayScreenRequest } from '../../redux/reducers/ducks/DisplayDuck';
import { HeaderTypography, TopBox } from '../Shared/shared';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

export default function OutletDetails() {
  const dispatch = useDispatch();

  // getting Authorization status from UseContext
  const { isAuthorize } = useDrawerContext();

  // get user data from redux State
  const userDetails = useSelector(
    (state) => state.authorize?.loginResponse?.user
  );

  const displays = userDetails.displays;

  const roles = ['super', 'manager', 'admin', 'reporter'];
  const accesses = userDetails?.role?.access;
  const isSuper = userDetails?.role?.name === 'super';

  const salesReport = [
    {
      name: 'Sale Report',
      route: 'sales-report'
    },
    { name: 'Menu Sales Report', route: 'menu-sales-report' },
    { name: 'Food Sales Report', route: 'food-Sales-report' },
    { name: 'Hourly Sales Report', route: 'hourly-sales-report' },
    { name: 'Consumption Report', route: 'consumption-report' },
    { name: 'Category-Wise Report', route: 'category-wise-report' },
    { name: 'Deleted Items Report', route: 'deleted-items-report' }
  ];

  const WalkSheet = [
    {
      name: 'Walk Sheet',
      route: 'walk-sheet',
      access: 'walksheet'
    }
  ];

  const TakeFeedback = [
    {
      name: 'TakeFeedback',
      route: 'take-feedbacks',
      access: 'takeFeedback'
    },
    {
      name: 'Outlet Feedback',
      route: 'feedbacks',
      access: 'outletFeedback'
    }
  ];

  const reports = [
    {
      name: 'Rider Sheet',
      route: 'rider-sheet',
      access: 'riderSheet'
    },
    { name: 'Kitchen Sheet', route: 'kitchen-sheet', access: 'kitchenSheet' },
    {
      name: 'Dispatch Sheet',
      route: 'dispatch-sheet',
      access: 'dispatchSheet'
    },
    {
      name: 'Inventory Report',
      route: 'inventory-report',
      access: 'inventoryControllers'
    },
    {
      name: `Outlet Delivery Monitor`,
      route: 'outlet-delivery-monitor',
      access: 'branchMonitor'
    },
    {
      name: `Delivery Traficking`,
      route: '/orders-status',
      access: 'statusReport'
    },
    {
      name: `Day Sheet`,
      route: 'day-sheet',
      access: 'inventoryControllers'
    }
  ];
  const filteredReports = isSuper
    ? reports
    : reports.filter((report) => accesses.includes(report.access));

  const filteredSheets = isSuper
    ? WalkSheet
    : WalkSheet.filter((sheet) => accesses.includes(sheet.access));

  const posList = [
    { name: 'POS', link: 'pos' },
    { name: 'Delivery POS', link: 'pos/delivery' },
    { name: 'DineIn POS', link: 'pos/dine-in' }
  ];
  return (
    <div>
      <TopBox>
        <HeaderTypography>Outlet Details</HeaderTypography>
      </TopBox>
      {!isAuthorize && (
        <OutletBox>
          <Grid container spacing={2}>
            {(isSuper ||
              accesses.includes('placeBranchOrders') ||
              accesses.includes('cashoutOrders')) && (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Point Of Sale</HeaderTypography>
                <ItemBox>
                  {posList.map((pos, i) => (
                    <React.Fragment key={i}>
                      <NavLink to={`${pos.link}`}>
                        <ReportChip label={pos.name} variant="outlined" />
                      </NavLink>
                    </React.Fragment>
                  ))}
                </ItemBox>
              </Grid>
            )}

            {(isSuper ||
              ['kitchenOrders', 'assembleOrders'].some((access) =>
                accesses.includes(access)
              )) && (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Display Screen</HeaderTypography>
                <ItemBox>
                  {displays.map((display, index) => {
                    return (
                      <Box key={index}>
                        <NavLink to={`display/${display.name.toLowerCase()}`}>
                          <ReportChip label={display.name} variant="outlined" />
                        </NavLink>
                      </Box>
                    );
                  })}
                  <NavLink to="cut-table">
                    <ReportChip label="Cut Table" variant="outlined" />
                  </NavLink>
                </ItemBox>
              </Grid>
            )}

            <Grid item lg={3} md={3} xs={6}>
              <HeaderTypography>Dispatch Screens</HeaderTypography>
              <ItemBox>
                {(isSuper || accesses.includes('dispatchOrders')) && (
                  <NavLink to="dispatch">
                    <ReportChip label="Dispatcher" variant="outlined" />
                  </NavLink>
                )}
                {(isSuper || accesses.includes('deliverOrders')) && (
                  <NavLink to="delivery-display">
                    <ReportChip label="Delivery" variant="outlined" />
                  </NavLink>
                )}
                {(isSuper || accesses.includes('serveOrders')) && (
                  <NavLink to="dinein-display">
                    <ReportChip label="Dine In" variant="outlined" />
                  </NavLink>
                )}
              </ItemBox>
            </Grid>

            {accesses.includes('salesReport') || isSuper ? (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Sales Reports</HeaderTypography>
                <ItemBox>
                  <Box>
                    {salesReport.map((item) => (
                      <NavLink to={item.route} key={item.route}>
                        <ReportChip label={item.name} variant="outlined" />
                      </NavLink>
                    ))}
                  </Box>
                </ItemBox>
              </Grid>
            ) : null}
            {filteredReports.length ? (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Data Sheets</HeaderTypography>
                <ItemBox>
                  <Box>
                    {filteredReports.map((item) => (
                      <NavLink to={item.route} key={item.route}>
                        <ReportChip label={`${item.name}`} variant="outlined" />
                      </NavLink>
                    ))}
                  </Box>
                </ItemBox>
              </Grid>
            ) : null}

            {roles.includes(userDetails?.role?.name) ||
              (userDetails?.role?.name === 'dispatcher' && (
                <Grid item lg={3} md={3} xs={6}>
                  <HeaderTypography>Data Sheets</HeaderTypography>
                  <ItemBox>
                    <NavLink to={`dispatch-sheet`}>
                      <ReportChip label="Dispatch Sheet" variant="outlined" />
                    </NavLink>
                  </ItemBox>
                </Grid>
              ))}

            {roles.includes(userDetails?.role?.name) ? (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Stock-Purchases</HeaderTypography>
                <ItemBox>
                  <NavLink to="purchase">
                    <ReportChip label="Purchase" variant="outlined" />
                  </NavLink>
                  <NavLink to="waste">
                    <ReportChip label="Waste" variant="outlined" />
                  </NavLink>
                  <NavLink to="expense">
                    <ReportChip label="Expense" variant="outlined" />
                  </NavLink>
                  {/* <NavLink to="transfer">
                    <ReportChip label="Transfer" variant="outlined" />
                  </NavLink> */}
                  <NavLink to="inventory-controller">
                    <ReportChip
                      label="Inventory Controller"
                      variant="outlined"
                    />
                  </NavLink>
                  <NavLink to="inventory-history">
                    <ReportChip label="Inventory History" variant="outlined" />
                  </NavLink>
                </ItemBox>
              </Grid>
            ) : null}

            {filteredSheets.length ? (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Sheets</HeaderTypography>
                <ItemBox>
                  <Box>
                    {filteredSheets.map((item) => (
                      <NavLink to={item.route} key={item.route}>
                        <ReportChip label={`${item.name}`} variant="outlined" />
                      </NavLink>
                    ))}
                  </Box>
                </ItemBox>
              </Grid>
            ) : null}

            {TakeFeedback.length ? (
              <Grid item lg={3} md={3} xs={6}>
                <HeaderTypography>Take Feedback</HeaderTypography>
                <ItemBox>
                  <Box>
                    {TakeFeedback.map((item) => (
                      <NavLink to={item.route} key={item.route}>
                        <ReportChip label={`${item.name}`} variant="outlined" />
                      </NavLink>
                    ))}
                  </Box>
                </ItemBox>
              </Grid>
            ) : null}
          </Grid>
        </OutletBox>
      )}
    </div>
  );
}
